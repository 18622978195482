<template>
  <div class="card">
    <div class="card-header border-0 pt-6 bg-white row m-0 sticky">
      <div class="d-flex p-0">
        <div class="card-title">
          <span>Setting Diskon</span>
        </div>
        <div class="card-toolbar" style="margin-left:auto">
        </div>
      </div>
    </div>
    <div class="card-body pt-0 tab-content">
      <div class="col row my-5">
        <div class="col-3 me-10">
          <div class="fv-row mb-7">
            <label class="required fs-6 fw-bold mb-2">Nama Toko</label>
            <el-select v-model="selectData.store_id" class="form-control p-0 border-0" placeholder="Pilih toko" size="large" clearable @change="getData()">
              <el-option
                v-for="(item, i) in listStore"
                :key="i"
                :label="item.name"
                :value="item.id"
              >
                <div class="d-flex">
                  <div class="col me-3">{{ item.name }}</div>
                  <div class="col-auto" v-if="item.status == 0">
                    <small class="text-gray-500"><i>Tidak Aktif</i></small>
                  </div>
                </div>
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div class="mb-7" v-if="selectData.store_id">
        <div class="d-flex p-0">
          <div class="card-title">
            <div class="filter d-flex align-items-center position-relative fs-6 text-gray-600">
              <div class="filter-item">
                <span>KATEGORI</span>
                <el-select v-model="filter.category_id" placeholder="Kategori" clearable @change="onFilter" @clear="onFilter">
                  <el-option v-for="(o, i) in listCategory" :key="i" :value="o.id" :label="o.name"/>
                </el-select>
              </div>
              <div class="filter-item">
                <span>BRAND</span>
                <el-select v-model="filter.brand_id" placeholder="Brand" clearable @change="onFilter" @clear="onFilter">
                  <el-option v-for="(o, i) in listBrand" :key="i" :value="o.id" :label="o.name"/>
                </el-select>
              </div>
              <div class="d-flex">
                <button class="btn btn-icon btn-light-primary me-3" @click="onFilter()"><i class="fas fa-search"></i></button>
                <button class="btn btn-icon btn-light-danger" @click="resetFilter()"><i class="fas fa-times"></i></button>
              </div>
            </div>
          </div>
        </div>
        <div class="table-fixed-header">
          <table class="table table-rounded table-row-dashed cursor-pointer table-hover" :class="{'overlay': loadingTable, 'overlay-block': loadingTable}">
            <thead>
            <tr class="text-start fw-bolder fs-7 gs-0 text-gray-400 border-gray-200 text-uppercase">
              <th>#</th>
              <th>Kategori</th>
              <th>Brand</th>
              <th>Voucher</th>
              <th class="text-end">Total Desain</th>
              <th class="text-end">Total Artikel</th>
              <th class="text-end">Action</th>
            </tr>
            </thead>
            <tbody class="fw-bold text-gray-600">
            <tr v-for="(item, i) in tableData" :key="i">
              <td scope="row">{{ item.index }}</td>
              <td>{{ item.category }}</td>
              <td>
                <div v-if="item.brand_name.length > 0">
                  <span v-for="o in item.brand_name.slice(0, 3)" :key="o">
                    <span class="badge badge-light me-1 mb-1">{{ o }}</span>
                  </span>
                  <span v-if="item.brand_name.length > 3" class="badge badge-light me-1 mb-1">...</span>
                </div>
                <div v-else>{{ '-' }}</div>
              </td>
              <td>
                <div v-if="item.voucher_name.length > 0">
                  <span v-for="o in item.voucher_name.slice(0, 3)" :key="o">
                    <span class="badge badge-primary me-1 mb-1">{{ o }}</span>
                  </span>
                  <span v-if="item.voucher_name.length > 3" class="badge badge-primary me-1 mb-1">...</span>
                </div>
                <div v-else>{{ '-' }}</div>
              </td>
              <td class="text-end">{{ item.total_desain }}</td>
              <td class="text-end">{{ item.total_artikel }}</td>
              <td class="text-end">
                <router-link :to="'#'" class="btn btn-light-primary btn-sm">View</router-link>
              </td>
            </tr>
            </tbody>
            <div class="overlay-layer card-rounded bg-dark bg-opacity-5" v-if="loadingTable">
              <div class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          </table>
        </div>
      </div>
    </div>

    <el-dialog
      v-model="viewImageVisible"
      :title="viewImageTitle"
      width="50%"
      lock-scroll
    >
      <inner-image-zoom :src="viewImageSrc" />
    </el-dialog>
  </div>
</template>

<script>
import {
  defineComponent,
  onMounted,
  ref,
  reactive,
  watchEffect,
  watch,
  computed,
  onUpdated,
} from "vue";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { formatIDR } from "@/helpers/functions";
//import TableLite from 'vue3-table-lite/src/components/TableLite.vue'
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import { ErrorMessage, Field, Form } from "vee-validate";
import InnerImageZoom from "vue-inner-image-zoom";
import { InfoFilled } from "@element-plus/icons-vue";
import { ElMessage, ElMessageBox, ElLoading, Elpagination } from "element-plus";
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
  components: {
    //Datatable,
    //Field,
    //Form,
    //ErrorMessage,
    "inner-image-zoom": InnerImageZoom,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();

    const img_url_mockup = ref(process.env.VUE_APP_IMAGE_MOCKUPS);
    const img_url_desain = ref(process.env.VUE_APP_IMAGE_DESAINS);
    const img_url_logo = ref(process.env.VUE_APP_IMAGE_LOGOS);

    const loadingTable = ref(false);
    const tableRef = ref(null);
    const modal = ref(null);

    const filterActive = ref(false);
    const filter = reactive({
      tipe: null,
      search: null,
      category_id: null,
      brand_id: null,
    });

    const tableData = reactive([]);

    const selectData = reactive({
      store_id: null,
    })

    const onFilter = async (type) => {
      if (Object.values(filter).every((o) => o == null || o.length == 0)) {
        //return
      }

      filterActive.value = false;

      loadingTable.value = true;

      await getData();

      loadingTable.value = false;

      Object.values(filter).every((o) => o == null || o.length == 0)
        ? (filterActive.value = false)
        : (filterActive.value = true);
    };

    const resetFilter = async (type) => {
      if (Object.values(filter).every((o) => o == null || o.length == 0)) {
        //return
      }

      Object.keys(filter).map((key) => (filter[key] = null));

      loadingTable.value = true;
      filterActive.value = false;

      await getData();

      loadingTable.value = false;
    };

    // PAGINATION
    const totalPage = ref(1);
    const currentPage = ref(1);

    const handlePageChange = async (page) => {
      loadingTable.value = true;
      tableRef.value && (tableRef.value.scrollTop = 0); // Reset scroll position

      await getData(page);
      loadingTable.value = false;
    };

    const listCategory = reactive([]);
    const listStore = reactive([]);
    const listBrand = reactive([]);

    const getCategory = async () => {
      try {
        listCategory.splice(0);

        await ApiService.setHeader();
        await ApiService.get("category")
          .then(({ data }) => {
            Object.assign(listCategory, data);
          })
          .catch(({ response }) => {
            console.log("Error getting list category");
          });
      } catch (e) {
        console.log(e.message);
      }
    };

    const getStore = async () => {
      await listStore.splice(0);
      await ApiService.setHeader();
      await ApiService.get("list_store")
        .then(({ data }) => {
          Object.assign(listStore, data.list_data);
        })
        .catch(({ response }) => {
          console.log("Error getting list store");
        });
    };

    const getListBrand = async () => {
      try {
        await ApiService.get('brand')
          .then((response) => {
            Object.assign(listBrand, response.data)
          })
          .catch((error) => {
            console.log('Error getting list brand');
          })

      } catch(e) {
        console.log(e.message)
      }
    }

    const getData = async () => {
      try {
        loadingTable.value = true;
        await tableData.splice(0);

        let store = selectData.store_id ? '?store=' + selectData.store_id : '?store=1';
        let category = filter.category_id ? '&category=' + filter.category_id : '';
        let brand = filter.brand_id ? '&brand=' + filter.brand_id : '';

        await ApiService.setHeader();
        await ApiService.get("voucher/product_discount" + store + category + brand)
          .then(async ({ data }) => {
            if (data.error) {
              throw new Error(data.messages);
            }

            Object.assign(tableData, data.data);
          })
          .catch((response) => {
            throw new Error(response);
          });

      } catch (e) {
        console.log(e);
        Swal.fire({
          title: e.message,
          icon: "error",
          buttonsStyling: false,
          showConfirmButton: false,
          timer: 2000,
        });
      }

      loadingTable.value = false;
    };

    const viewImageSrc = ref(null);
    const viewImageTitle = ref("");
    const viewImageVisible = ref(false);

    const viewImage = (data) => {
      viewImageTitle.value = data.logo;
      viewImageVisible.value = true;
      viewImageSrc.value = img_url_logo.value + data.logo;
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs("Product Voucher", ["Product Voucher"]);
      getCategory();
      getStore();
      getListBrand();
    });

    return {
      route,
      router,
      loadingTable,
      tableRef,
      img_url_mockup,
      img_url_desain,
      img_url_logo,
      viewImage,
      viewImageSrc,
      viewImageVisible,
      viewImageTitle,
      onFilter,
      getData,
      resetFilter,
      filter,
      filterActive,
      listCategory,
      listBrand,
      tableData,
      selectData,
      handlePageChange,
      listStore,
      totalPage,
      currentPage,
      formatIDR,
    };
  },
});
</script>